import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { PlayArrow, Report } from '@material-ui/icons'
import { loadAllImages } from '../helpers/functions'
import SliderFilter from './SliderFilter'

const WorkPhotosSlider = ({ images, classes }) => {
	const interval = useRef()

	const [blobImages, setBlobImages] = useState([])
	const [loadingImages, setLoadingImages] = useState(true)
	const [imageNumber, setImageNumber] = useState(0)
	const [brightness, setBrightness] = useState('100%')
	const [start, setStart] = useState(true)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		return removeInterval
	}, [])
	useEffect(() => {
		if (Boolean(images.length)) {
			loadAllImages({ images, userInfo }).then((blobImagesLoaded) => {
				if (blobImagesLoaded?.length) {
					setBlobImages(blobImagesLoaded)
					setLoadingImages(false)
					startInterval(blobImagesLoaded)
				} else {
					setLoadingImages(false)
				}
			})
		}
	}, [images])

	const startInterval = (blobImagesLoaded) => {
		let last = false
		setTimeout(() => {
			setBrightness('0%')
		}, 850)
		interval.current = setInterval(() => {
			setTimeout(() => {
				setBrightness('100%')
			}, 150)
			setImageNumber((prev) => {
				if (prev === blobImagesLoaded.length - 1) {
					removeInterval()
					last = true
					return 0
				} else return prev + 1
			})
			!last &&
				setTimeout(() => {
					setBrightness('0%')
				}, 850)
		}, 1000)
	}
	const removeInterval = () => {
		clearInterval(interval?.current)
		setStart(false)
	}
	const handleClickImage = () => {
		if (start) {
			removeInterval()
		} else {
			setStart(true), startInterval(blobImages)
		}
	}
	const handleOnChangePhoto = (event, values) => {
		setImageNumber(values)
	}

	return (
		<>
			<div className={classes.videoContainer}>
				{Boolean(blobImages?.length) && (
					<span className={classes.countText}>{`${imageNumber + 1}/${blobImages.length}`}</span>
				)}
				{!start && Boolean(blobImages?.length) && (
					<Tooltip title='Ver Video' placement='right'>
						<div className={classes.btnPlay}>
							<IconButton
								className={classes.iconBtn}
								aria-label='visibility'
								size='medium'
								onClick={() => [setStart(true), startInterval(blobImages)]}
							>
								<PlayArrow className={classes.icon} />
							</IconButton>
						</div>
					</Tooltip>
				)}
				{loadingImages ? (
					<div className={classes.loaderContainer}>
						<CircularProgress sx={{ color: 'white' }} />
						<span>Cargando video</span>
					</div>
				) : !loadingImages && Boolean(blobImages?.length) ? (
					<div name='image-body' className={classes.imageBody} style={{ filter: `brightness(${brightness})` }}>
						<div
							id='blur'
							className={classes.blurBackground}
							style={{
								backgroundImage: `url(${blobImages[imageNumber].src})`,
							}}
						></div>
						<img
							onClick={handleClickImage}
							className={
								blobImages[imageNumber].width > blobImages[imageNumber].height ? classes.landscape : classes.portrait
							}
							src={blobImages[imageNumber].src}
						/>
					</div>
				) : (
					<div className={classes.loaderContainer}>
						<Report className={classes.icon} />
						<span>Ocurrió un problema al descargar las imágenes</span>
					</div>
				)}
			</div>
			<div>
				<SliderFilter
					disabled={!loadingImages && !Boolean(blobImages?.length)}
					sx={{ width: '100%' }}
					valueLabelDisplay='auto'
					value={imageNumber + 1}
					step={1}
					onChange={handleOnChangePhoto}
					getAriaValueText={(value) => value}
					valueLabelFormat={(value) => value}
					min={1}
					max={blobImages?.length || 1}
				/>
			</div>
		</>
	)
}

export default WorkPhotosSlider
