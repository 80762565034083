import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { defaultDateFormat, defaultType, types } from './values'

export const currencyFormat = new Intl.NumberFormat('es-ES', {
	style: 'currency',
	currency: 'EUR',
})
export const getNumber = (value) => {
	if (isNaN(Number(value))) {
		return numberFormat(value) || 0
	} else return Number(value)
}
export const stringFormat = (inputString) => {
	const lowerString = String(inputString).toLowerCase()
	const tildesMap = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		ü: 'u',
		ñ: 'n',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
		Ü: 'U',
		Ñ: 'N',
	}

	return lowerString.replace(/[áéíóúüñÁÉÍÓÚÜÑ]/g, (match) => tildesMap[match] || match)
}
export const numberFormat = (inputString) => {
	const stringNumber = String(inputString).replaceAll('.', '').replaceAll(' ', '').replace('€', '').replace(',', '.')
	return Number(stringNumber)
}
export const isValidDate = (input) => {
	const dateRegex = /^(?:\d{4}|(0[1-9]|1[0-2])\/\d{4}|(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4})$/
	return dateRegex.test(input)
}

export const filterString = ({ data, filterValues, errorInputDate, columns }) => {
	if (!Object.values(filterValues)?.length) {
		return data
	}

	const filteredResult = data.filter((row) =>
		Object.keys(filterValues).reduce((result, field) => {
			const { header, accessor, align, size, type, dateFormat, disabledDateInput, nullValue } = columns?.find(
				(column) => column?.accessor === field,
			)
			const isDate = type === types.date

			const rowValue = row[field] ? row[field] : isDate ? null : ''
			const inputValue = stringFormat(filterValues[field])

			const rowFormat = isDate
				? rowValue
					? format(new Date(rowValue), defaultDateFormat, { locale: es })
					: stringFormat(nullValue)
				: stringFormat(rowValue)
			const isErrorDate = Boolean(errorInputDate?.[field])
			const filterCond = isErrorDate ? true : rowFormat?.includes(inputValue)

			return result && filterCond
		}, true),
	)
	return filteredResult
}

export const sortDataFunc = ({ data, sortColumn, sortOrder, columns }) => {
	if (!sortColumn?.length || !sortOrder?.length) {
		return data
	}

	const { header, accessor, align, size, type, dateFormat } = columns?.find((column) => column?.accessor === sortColumn)
	const typeToSort = type || defaultType

	let sortFunc
	if (typeToSort === 'text') {
		sortFunc = (rowA, rowB) => {
			const textA = rowA[sortColumn] || ''
			const textB = rowB[sortColumn] || ''
			if (sortOrder === 'asc') {
				return textA.localeCompare(textB)
			} else {
				return textB.localeCompare(textA)
			}
		}
	} else if (typeToSort === 'date') {
		sortFunc = (rowA, rowB) => {
			const dateA = new Date(rowA[sortColumn] || null)
			const dateB = new Date(rowB[sortColumn] || null)

			if (sortOrder === 'asc') {
				return dateA > dateB ? 1 : -1
			} else {
				return dateA > dateB ? -1 : 1
			}
		} //fix sort function
	} else {
		sortFunc = (rowA, rowB) => {
			const numA = rowA[sortColumn] || 0
			const numB = rowB[sortColumn] || 0
			if (sortOrder === 'asc') {
				return numberFormat(numA) - numberFormat(numB)
			} else {
				return numberFormat(numB) - numberFormat(numA)
			}
		}
	}
	const sortData = data.toSorted(sortFunc)
	return sortData
}

export const switchFilter = ({ data, switchs }) => {
	const switchsEntries = Object.entries(switchs || {})
	if (!switchsEntries?.length) {
		return data
	}
	const switchFilterData = data.filter((row) =>
		switchsEntries?.reduce((result, entrie) => {
			const { value, filter } = entrie[1]
			return !!value ? result && filter(row) : result
		}, true),
	)
	return switchFilterData
}

/*
Column Example:
{
  header: 'Proyecto',
  accessor: 'project',
  size: 'xs'|'sm'|'md'|'lg' default: 'md',
  nullValue: 'Sin Fecha', default: 'No establecido'
  type: 'date'|'text'|'number'|'currency' deafult: 'text',
  cellAlign: 'left'|'right'|'center' default:'left',
  headerAlign: 'left'|'right'|'center' default:'left',
  dateFormat: 'dd/MM/yyyy hh:mm aaa' | default: 'dd/MM/yyyy',
  cell: ({row, cell})=>React.Node,
  disabledSearch: true|false deafult:false
  disabledSort: true|false deafult:false
  hidden: true | false default: false
  width: '100px' 
  disabledDateInput: true | false default: false,
}
*/
