import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Badge } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Clearfix from 'components/Clearfix/Clearfix'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import InputsLegalPersonUpdateByAdmin from './components/InputsLegalPersonUpdateByAdmin'
import InputsPhysicalPersonUpdateByAdmin from './components/InputsPhysicalPersonUpdateByAdmin'
import { matchGender } from 'shared/matchData/matchData'
import {
	INVESTOR_PROFILE_UPDATE_RESET,
	INVESTORS_PROFILE_BY_USER_RESET,
} from 'local_redux/constants/investorProfileConstants'
import { updateInvestorProfile } from 'local_redux/actions/investorProfileActions'
import styles from './styles/InvestorProfileUpdateModalByAdminStyles'

const useStyles = makeStyles(styles)

const InvestorProfileUpdateModalByAdmin = ({
	handleCloseInvestorUpdateModal,
	investorProfileModal,
	showInvestorUpdateInfo,
	investorsProfileListData,
}) => {
	const fileInput = createRef()
	const dispatch = useDispatch()
	const classes = useStyles()
	const isLegal = showInvestorUpdateInfo.personType === 'legal'

	const [infoModalData, setModalData] = useState({
		...showInvestorUpdateInfo,
		location: showInvestorUpdateInfo.location || 'Madrid',
		province: showInvestorUpdateInfo.province || 'Madrid',
		postalCode: showInvestorUpdateInfo.postalCode || 28001,
	})
	const [errorTaxResidenceFile, setErrorTaxResidenceFile] = useState('')
	const [imagesUrl, setImagesUrl] = useState()
	const [prescribers, setPrescribers] = useState([])

	const { loadingInvestorProfileUpdate, errorInvestorProfileUpdate, successInvestorProfileUpdate } = useSelector(
		(state) => state.investorProfileUpdate,
	)

	useEffect(() => {
		if (investorsProfileListData) {
			const prescribers = investorsProfileListData.filter(
				(investor) => investor._id !== showInvestorUpdateInfo._id && investor.isPrescriber,
			)
			setPrescribers(prescribers)
		}
	}, [investorsProfileListData])

	useEffect(() => {
		let timeOut = ''
		if (successInvestorProfileUpdate) {
			timeOut = setTimeout(() => {
				dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
				dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
				handleCloseInvestorUpdateModal()
			}, 2000)
		}

		return () => clearTimeout(timeOut)
	}, [successInvestorProfileUpdate])

	useEffect(() => {
		return () => dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
	}, [dispatch])

	useEffect(() => {
		setImagesUrl(infoModalData.logo)
	}, [])

	const updateInvestorProfileHandler = (e) => {
		e.preventDefault()

		if (infoModalData.country !== 'España' && !infoModalData.residenceCertificate) {
			return setErrorTaxResidenceFile('Certificado de residencia fiscal obligatorio, salvo España')
		}
		const matchData = {
			...infoModalData,
			gender: matchGender(infoModalData.gender),
		}

		dispatch(updateInvestorProfile(matchData))
	}

	const handleSelectFile = () => {
		fileInput.current.click()
	}
	const handleChangeFile = (e) => {
		e.preventDefault()

		let pdfFile = e.target.files[0]

		setModalData({ ...infoModalData, residenceCertificate: pdfFile })
	}
	const handleRemoveFile = () => {
		setModalData({ ...infoModalData, residenceCertificate: '' })
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={investorProfileModal}
			keepMounted
			onClose={handleCloseInvestorUpdateModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<form onSubmit={updateInvestorProfileHandler}>
				<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseInvestorUpdateModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4 className={classes.modalTitle}>Editar Perfil</h4>
				</DialogTitle>

				<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
					{isLegal ? (
						<InputsLegalPersonUpdateByAdmin
							userInfo={infoModalData}
							setUserInfo={setModalData}
							investorsProfileListData={prescribers}
							handleChangeFile={handleChangeFile}
							fileInput={fileInput}
							handleSelectFile={handleSelectFile}
							setErrorTaxResidenceFile={setErrorTaxResidenceFile}
						/>
					) : (
						<InputsPhysicalPersonUpdateByAdmin
							userInfo={infoModalData}
							setUserInfo={setModalData}
							investorsProfileListData={prescribers}
							handleChangeFile={handleChangeFile}
							fileInput={fileInput}
							handleSelectFile={handleSelectFile}
							setErrorTaxResidenceFile={setErrorTaxResidenceFile}
						/>
					)}
					<GridContainer spacing={3}>
						{infoModalData.residenceCertificate && (
							<GridItem xs={12}>
								<Badge
									badgeContent={
										<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveFile()}>
											<Close />
										</Button>
									}
									className={classes.fileBadge}
								>
									<div className={classes.fileDiv}>
										{typeof infoModalData.residenceCertificate === 'object'
											? infoModalData.residenceCertificate.name.length > 20
												? infoModalData.residenceCertificate.name.slice(1, 10) +
												  '...' +
												  infoModalData.residenceCertificate.name.slice(
														infoModalData.residenceCertificate.name.length - 6,
														infoModalData.residenceCertificate.name.length,
												  )
												: infoModalData.residenceCertificate.name
											: infoModalData.residenceCertificate.length > 20
											? infoModalData.residenceCertificate.slice(1, 10) +
											  '...' +
											  infoModalData.residenceCertificate.slice(
													infoModalData.residenceCertificate.length - 6,
													infoModalData.residenceCertificate.length,
											  )
											: infoModalData.residenceCertificate}
									</div>
								</Badge>
							</GridItem>
						)}

						{errorTaxResidenceFile && !infoModalData.residenceCertificate && (
							<GridItem xs={12} sm={12}>
								<SnackbarContent message={errorTaxResidenceFile} color='warning' />
							</GridItem>
						)}
						{errorInvestorProfileUpdate && (
							<GridItem xs={12} sm={12}>
								<SnackbarContent message={errorInvestorProfileUpdate} color='danger' />
							</GridItem>
						)}
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button type='submit' color={successInvestorProfileUpdate ? 'success' : 'primary'} block>
								{loadingInvestorProfileUpdate
									? 'Actualizando'
									: successInvestorProfileUpdate
									? 'Perfil Actualizado'
									: 'Actualizar Perfil'}
							</Button>
							<Clearfix />
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default InvestorProfileUpdateModalByAdmin
