const styles = (theme) => ({
	tableContainer: {
		'&::-webkit-scrollbar': {
			width: '8px !important',
			height: '8px !important',
		},
		'&::-webkit-scrollbar-track': {
			background: '#e9e9e9',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#c3c0b2',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			backgroundColor: '#CAC7C7',
		},
		lineHeight: '1.4rem',
		boxShadow: 'none !important',
	},
	iconSort: {
		width: '10px',
		display: 'block',
		margin: '-10px 0',
	},
	btnSort: {
		backgroundColor: 'transparent',
		color: 'black',
		border: 'none',
		outline: 'none',
		boxShadow: 'none',
		marginTop: '8px',
		cursor: 'pointer',
	},
	input: {
		width: '100%',
		border: 'none',
		fontFamily: 'system-ui',
		padding: '0 5px',
		borderBottom: '1px solid #cdc8be',
		height: '30px',
		transition: 'border-bottom 0.1s ease-in-out',
		'&:focus': {
			borderBottom: '2px solid #ccc',
		},
		'&:focus::before, &:focus::after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			width: '50%',
			height: '2px',
			backgroundColor: '#ccc',
			transition: 'width 0.1s ease-in-out',
		},
		'&:focus::before': {
			left: '50%',
			transform: 'translateX(-50%)',
		},
		'&:focus::after': {
			right: '50%',
			transform: 'translateX(50%)',
		},
	},
	rows: {
		'&:first-child': {
			position: 'sticky',
			left: '0px',
			width: '250px',
			backgroundColor: 'inherit',
			zIndex: 1,
		},
		border: 'none !important',
		fontSize: '0.8rem !important',
		lineHeight: '1.4rem !important',
		fontWeight: 'inherit !important',
		padding: '4px 2px !important',
	},
	headerText: {
		backgroundColor: 'white !important',
		fontWeight: '500 !important',
	},
	tableBody: {
		backgroundColor: 'white',
		'& > tr:nth-child(even)': {
			backgroundColor: '#fff !important' /* Color de fondo para filas pares */,
		},
		'& > tr:nth-child(odd)': {
			backgroundColor: '#f2f1eb !important' /* Color de fondo para filas pares */,
		},
		'& > tr:hover': {
			backgroundColor: '#f2f2f2  !important',
			color: '#000',
			fontWeight: '500 !important',
		},
	},
	xs: {
		width: '100px !important',
	},
	sm: {
		width: '150px !important',
	},
	md: {
		width: '200px !important',
	},
	lg: {
		width: '350px !important',
	},
	paperContainer: {
		width: '100%',
		maxHeight: '90vh',
		overflow: 'hidden',
		boxShadow: 'none !important',
	},
})

export default styles
