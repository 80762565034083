import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'
import { getPrescriberProfiles } from 'local_redux/actions/investorProfileActions'
import { INVESTOR_PRESCRIBERS_PROFILES_RESET } from 'local_redux/constants/investorConstant'
import Loader from 'components/Loader/Loader'
import styles from './styles/prescriptorsSelectStyles'

const useStyles = makeStyles(styles)

function PrescriptorsSelect({ userInfo, setUserInfo }) {
	const [data, setData] = useState([])
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingInvestorPrescribers, prescribers } = useSelector((state) => state.investorPrescriberProfiles)

	const onChangeFunction = (e) => {
		setUserInfo({ ...userInfo, prescriber: e.target.value })
	}

	useEffect(() => {
		dispatch(getPrescriberProfiles())

		return () => {
			dispatch({ type: INVESTOR_PRESCRIBERS_PROFILES_RESET })
		}
	}, [])
	useEffect(() => {
		if (prescribers) setData([...prescribers])
	}, [prescribers])

	return (
		<FormControl variant='standard' className={classes.formControl}>
			{!loadingInvestorPrescribers ? (
				<>
					<InputLabel id='investor-prescriber-label' sx={{ minWidth: '100%' }}>
						Perfil Prescriptor
					</InputLabel>
					<Select
						labelId='investor-prescriber-label'
						id='investor-prescriber'
						value={userInfo.prescriber ? userInfo.prescriber : userInfo.prescriber === null ? 'null' : ''}
						onChange={onChangeFunction}
						label='Prescriber'
						sx={{ minWidth: '100%' }}
						fullWidth
					>
						<MenuItem value={userInfo.prescriber} disabled>
							Selecciona Uno
						</MenuItem>
						<MenuItem value={'null'}>Sin Prescriptor</MenuItem>
						{data &&
							data.map((profile) => (
								<MenuItem key={profile._id} value={profile._id}>
									{profile.businessName}
								</MenuItem>
							))}
					</Select>
				</>
			) : (
				<CircularProgress style={{ color: '#333333' }} size='20px' />
			)}
		</FormControl>
	)
}

export default PrescriptorsSelect
