import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { getInvestmentsByProfileAndProject } from 'local_redux/actions/investmentActions'
import Loader from 'components/Loader/Loader'
import { format } from 'date-fns'

const InvestmentsList = ({ infoModalData, locationForDecline, investmentModalComponent }) => {
  const dispatch = useDispatch()

  const { loadingInvestmentsByProfileAndProject, investmentsByProfileAndProjectData } = useSelector(
    (state) => state.investmentsByProfileAndProject
  )

  useEffect(() => {
    if (!investmentsByProfileAndProjectData) {
      dispatch(getInvestmentsByProfileAndProject(infoModalData._id, infoModalData.profile?._id))
    }
  }, [infoModalData])

  const columns = [
    { id: 'created', label: 'Fecha de Inversión' },
    { id: 'amount', label: 'Importe' },
  ]

  if (locationForDecline) {
    columns.push({ id: 'action', label: 'Acciónes' })
  } else {
    columns.push({ id: '' })
  }

  return (
    <>
      {loadingInvestmentsByProfileAndProject ? (
        <GridContainer>
          <GridItem xs={12}>
            <Loader
              message={'Cargando inversiones de perfil'}
              config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
            />
          </GridItem>
        </GridContainer>
      ) : investmentsByProfileAndProjectData?.length ? (
        <GridContainer style={{ marginTop: 30 }}>
          <GridItem xs={12} style={{ textAlign: 'center', fontSize: 19, fontWeight: '500' }}>
            {investmentModalComponent && <p>Inversiones Anteriores</p>}
          </GridItem>
          <GridItem xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label='custom pagination table'>
                <TableBody>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ fontWeight: 500 }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                  {investmentsByProfileAndProjectData?.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component='th' scope='row'>
                        {format(new Date(row.investmentDate), 'dd-MM-yyyy')}
                      </TableCell>
                      <TableCell>{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      ) : (
        <GridItem xs={12}></GridItem>
      )}
    </>
  )
}

export default InvestmentsList
