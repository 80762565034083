import { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { AppBar, Toolbar, Hidden, Drawer, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { Menu, PersonAdd, Fingerprint } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import styles from './styles/authNavbarStyle'

const useStyles = makeStyles(styles)

const AuthNavbar = ({ color, brandText }) => {
  const classes = useStyles()

  const [openDrawer, setOpenDrawer] = useState(false)

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer)
  }
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false
  }

  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  })

  let list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
        <NavLink
          to={'/auth/login'}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute('/auth/login'),
          })}
        >
          <Fingerprint className={classes.listItemIcon} />
          <ListItemText primary={'Acceso'} disableTypography={true} className={classes.listItemText} />
        </NavLink>
      </ListItem>
      {/* <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
        <NavLink
          to={'/auth/register'}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute('/auth/register'),
          })}
        >
          <PersonAdd className={classes.listItemIcon} />
          <ListItemText primary={'Register'} disableTypography={true} className={classes.listItemText} />
        </NavLink>
      </ListItem> */}
    </List>
  )
  return (
    <AppBar position='static' className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button href='/' className={classes.title} color='transparent'>
            {brandText}
          </Button>
        </div>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color='transparent'
            justIcon
            aria-label='open drawer'
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant='temporary'
              anchor={'right'}
              open={openDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.string,
}

export default AuthNavbar
