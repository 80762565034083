import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import ReactTableVirtuosoStyled from './components/ReactTableVirtuoso'
import { filterString, isValidDate, sortDataFunc, switchFilter } from './helpers/functions'
import { onChangeDelayTime } from 'shared/helpers/onchange-delay-time'
import EmptyPlaceholder from './components/EmptyPlaceholder'
import { defaultNameToSaveFilter, types } from './helpers/values'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'

const ReactVirtualizedTable = ({
	tableId = 100,
	columns, //Array<Column> [{accessor, header, size, ...}, ...]
	data, //Array<Data> [{project, actions, ...}]
	keepHeader = true, //Boolean //Not implemented yet
	loading = true, //Boolean
	success, //Boolean
	error, //String
	saveFilters = false, //Boolean
	nameFiltersSaved = defaultNameToSaveFilter, //'lead-list'
	defaultSortColumn = '', //Field(String) //Not implemented yet
	defaultSortOrder = '', //'asc'|'desc' //Not implemented yet
	resetSortWithChanges, //Boolean //Not implemented yet
	emptyText, //String
	switchs = {}, //Obj //{nameSwitch: {value, filter}, nameSwitch2: {value, filter}} //implementation in progress
}) => {
	const parentRef = useRef(null) //Test parent scroll "not implemented yet"
	const inputsRef = useRef(null) //ref to save delay function
	const virtuosoRef = useRef(null) //table ref to reset scroll
	const tableHeaderRef = useRef(null)
	const maxHeight = useMemo(() => window.innerHeight * 0.9, [])
	const filtersSaved = localStorage.getItem(String(nameFiltersSaved))

	const [dataRows, setDataRows] = useState([]) //Data to reset
	const [dataShow, setDataShow] = useState([]) //Data to show in table
	const [filterValues, setFilterValues] = useState({}) //filter fields and values to filter data
	const [errorInputDate, setErrorInputDate] = useState({})
	const [sortColumn, setSortColumn] = useState(defaultSortColumn) //order field to sort data
	const [sortOrder, setSortOrder] = useState(defaultSortOrder) //order to sort data
	const [height, setHeight] = useState(maxHeight) //Height to change table size

	const { saveFilteredDataRows, saveFilteredDataColumns, resetFilteredData } = useContext(
		FilterReactVirtualizedTableContext,
	)

	const paperHeight = useMemo(() => {
		const tableHeaderHeight = tableHeaderRef?.current?.offsetHeight
		if (loading || error) {
			return `${tableHeaderHeight + 10 || 0}px`
		}

		const totalHeight = height + (tableHeaderHeight || 0) + 10

		if (totalHeight < maxHeight) return `${totalHeight}px`
		if (totalHeight > maxHeight) return '90vh'
	}, [height, loading])

	const handleFilterChange = ({ event, accessor, type, disabledDateInput }) => {
		let value = event.target.value
		if (type === types.date && !disabledDateInput) {
			value = value.replace(/[^0-9/]/g, '')
			const isValidDateValue = isValidDate(value)
			if (!isValidDateValue && value.length > 0) {
				setErrorInputDate((prev) => ({ ...prev, [accessor]: 'Debe colocar una fecha válida' }))
			} else {
				setErrorInputDate((prev) => ({ ...prev, [accessor]: '' }))
			}
		}
		setFilterValues((prev) => ({ ...prev, [accessor]: value }))
	}
	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? '' : 'asc') // Cambiar el orden si ya está ordenado por la misma columna
		} else {
			setSortColumn(column) // Ordenar ascendentemente por la columna seleccionada
			setSortOrder('asc')
		}
	}
	const handleHeight = (heightNumber) => {
		setHeight(heightNumber)
	}
	const filterData = () => {
		const dataFiltered = filterString({ data: dataRows, filterValues, errorInputDate, columns })
		setDataShow(dataFiltered)
		saveFilteredDataRows({ tableId, value: dataFiltered })
	}
	const sortData = () => {
		const dataSorted = sortDataFunc({ data: dataShow, sortColumn, sortOrder, columns })
		setDataShow(dataSorted)
	}
	const sortDefaultData = () => {
		const dataSorted = sortDataFunc({ data: dataShow, defaultSortColumn, defaultSortOrder, columns })
		setDataShow(dataSorted)
		setSortColumn(defaultSortColumn)
		setSortOrder(defaultSortOrder)
	}
	const switchFilterData = () => {
		const dataSwitchFiltered = switchFilter({ data, switchs })
		setDataRows(dataSwitchFiltered)
	}

	useEffect(() => {
		if (filtersSaved && saveFilters) {
			const filterParse = JSON.parse(filtersSaved)
			setFilterValues(filterParse)
		}
	}, [])
	useEffect(() => {
		if (data?.length > 0) {
			const dataSwitchFiltered = switchFilter({ data, switchs })
			setDataRows(dataSwitchFiltered)
			saveFilteredDataColumns({ tableId, value: columns })
		} else {
			setDataRows([])
		}
	}, [data])
	useEffect(() => {
		if (dataRows?.length > 0) {
			const newSortColumn = resetSortWithChanges ? defaultSortColumn : sortColumn
			const newSortOrder = resetSortWithChanges ? defaultSortOrder : sortOrder
			const dataFiltered = filterString({ data: dataRows, filterValues, columns })
			const dataSorted = sortDataFunc({
				data: dataFiltered,
				sortColumn: newSortColumn,
				sortOrder: newSortOrder,
				columns,
			})
			setDataShow(dataSorted)
			saveFilteredDataRows({ tableId, value: dataSorted })
		} else {
			setDataShow([])
		}
	}, [dataRows])
	useEffect(() => {
		if (saveFilters) {
			localStorage.setItem(String(nameFiltersSaved), JSON.stringify(filterValues))
		}
		if (resetSortWithChanges) {
			sortDefaultData()
		}
		if (dataRows?.length) {
			onChangeDelayTime(inputsRef, filterData, 0.5)
		}
	}, [filterValues])
	useEffect(() => {
		sortData()
	}, [sortColumn, sortOrder])
	useEffect(() => {
		virtuosoRef?.current.scrollToIndex(0)
	}, [paperHeight])
	useEffect(() => {
		return () => {
			setDataRows([])
			setDataShow([])
			resetFilteredData({ tableId })
		}
	}, [])
	useEffect(() => {
		if (error) {
			setDataRows([])
			setDataShow([])
			resetFilteredData({ tableId })
		}
	}, [error])
	useEffect(() => {
		switchFilterData()
	}, [switchs])
	return (
		<>
			<Paper
				id='paper-container'
				ref={parentRef}
				sx={{
					width: '100%',
					maxHeight: '90vh',
					height: paperHeight,
					overflow: 'hidden',
					boxShadow: 'none !important',
				}}
			>
				<ReactTableVirtuosoStyled
					referer={virtuosoRef}
					tableHeaderRef={tableHeaderRef}
					columns={columns}
					data={dataShow}
					loading={loading}
					success={success}
					error={error}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					filterValues={filterValues}
					handleSort={handleSort}
					handleFilterChange={handleFilterChange}
					handleHeight={handleHeight}
					errorInputDate={errorInputDate}
					emptyText={emptyText}
				/>
			</Paper>
			{(!dataShow?.length || loading) && (
				<EmptyPlaceholder
					emptyText={emptyText}
					isLoading={loading}
					isSuccess={success}
					isError={Boolean(error)}
					error={error}
				/>
			)}
		</>
	)
}

const propTypesForObject = {
	header: PropTypes.string.isRequired,
	accessor: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
	nullValue: PropTypes.string,
	type: PropTypes.oneOf(['date', 'text', 'number', 'currency']),
	cellAlign: PropTypes.oneOf(['left', 'right', 'center']),
	headerAlign: PropTypes.oneOf(['left', 'right', 'center']),
	dateFormat: PropTypes.string,
	cell: PropTypes.func,
	disabledSearch: PropTypes.bool,
	hidden: PropTypes.bool,
	disabledDateInput: PropTypes.bool,
}

ReactVirtualizedTable.propTypes = {
	tableId: PropTypes.number,
	columns: PropTypes.arrayOf(PropTypes.shape(propTypesForObject)).isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	keepHeader: PropTypes.bool,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	error: PropTypes.string,
	saveFilters: PropTypes.bool,
	nameFiltersSaved: PropTypes.string,
	defaultSortColumn: PropTypes.string,
	defaultSortOrder: PropTypes.oneOf(['asc', 'desc']),
	resetSortWithChanges: PropTypes.bool,
	emptyText: PropTypes.string,
}

export default ReactVirtualizedTable
