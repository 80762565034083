import React, { useEffect, useState, createRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  preBackgroundImg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transition: (props) => (props.isAnimationDisabled ? 'none' : 'opacity 1s ease'),
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    zIndex: -2,
    opacity: (props) => (props.backgroundImageLoaded ? 0 : 1),
  },

  backgroundImg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transition: (props) => (props.isAnimationDisabled ? 'isAnimationDisabled' : 'opacity 1s ease'),
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    zIndex: -1,
    opacity: (props) => (props.backgroundImageLoaded ? 1 : 0),
    backgroundImage: (props) => `url(${props.loadedImage})`,
  },
}))

function BackgroundImage({ preImage, loadedImage }) {
  const [backgroundImageLoaded, setBackGroundImageLoaded] = useState(false)
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(false)
  const [preDivRef] = useState(createRef())
  const [loadedDivRef] = useState(createRef())

  const classes = useStyles({
    isAnimationDisabled,
    backgroundImageLoaded,
    loadedImage,
  })

  useEffect(() => {
    const preBackgroundImg = new Image()
    preBackgroundImg.src = preImage
    preBackgroundImg.onload = () => {
      preDivRef &&
        preDivRef.current &&
        !backgroundImageLoaded.complete &&
        preDivRef.current.setAttribute('style', `background-image: url(${preImage})`)

      const backgroundImg = new Image()
      backgroundImg.src = loadedImage
      setIsAnimationDisabled(backgroundImg.complete)
      backgroundImg.onload = () => {
        loadedDivRef &&
          loadedDivRef.current &&
          loadedDivRef.current.setAttribute('style', `background-image: url(${loadedImage})`)
        if (backgroundImg.complete) {
          setTimeout(() => {
            setBackGroundImageLoaded(true)
          }, 3000)
        }
      }
    }
  }, [loadedDivRef, loadedImage, preDivRef, preImage])

  return (
    <React.Fragment>
      <div ref={preDivRef} className={classes.preBackgroundImg} />
      <div ref={loadedDivRef} className={classes.backgroundImg} />
    </React.Fragment>
  )
}

export default BackgroundImage
