import React, { useState, useCallback, useEffect, useRef } from 'react'

const SCROLL_BOX_MIN_WIDTH = 20

export default function CustomScrollDiv({ scrollHostRef, hovering, ...restProps }) {
	const [scrollBoxWidth, setScrollBoxWidth] = useState(SCROLL_BOX_MIN_WIDTH)
	const [scrollBoxLeft, setScrollBoxLeft] = useState(0)
	const [isDragging, setDragging] = useState(false)
	const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0)
	const [scrollContainerWidth, setScrollContainerWidth] = useState(false)

	const handleScroll = useCallback(() => {
		if (!scrollHostRef) {
			return
		}
		const scrollHostElement = scrollHostRef.current
		const { scrollLeft, scrollWidth, offsetWidth } = scrollHostElement

		let newLeft = (parseInt(scrollLeft, 10) / parseInt(scrollWidth, 10)) * offsetWidth
		newLeft = Math.min(newLeft, offsetWidth - scrollBoxWidth)
		setScrollBoxLeft(newLeft)
	}, [])

	const handleScrollThumbMouseDown = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation()

		setScrollThumbPosition(e.clientX)
		setDragging(true)
	}, [])

	const handleMouseUp = useCallback(
		(e) => {
			if (isDragging) {
				e.preventDefault()
				setDragging(false)
			}
		},
		[isDragging],
	)

	const handleDocumentMouseMove = useCallback(
		(e) => {
			if (isDragging) {
				e.preventDefault()
				e.stopPropagation()
				const scrollHostElement = scrollHostRef.current
				const { scrollWidth, offsetWidth } = scrollHostElement

				let deltaX = e.clientX - lastScrollThumbPosition
				let newScrollHostLeft = (scrollWidth / offsetWidth) * deltaX

				setScrollThumbPosition(e.clientX)
				setScrollBoxLeft(Math.min(Math.max(0, scrollBoxLeft + deltaX), offsetWidth - scrollBoxWidth))
				scrollHostElement.scrollLeft = Math.min(
					scrollHostElement.scrollLeft + newScrollHostLeft,
					scrollWidth - offsetWidth,
				)
			}
		},
		[isDragging, lastScrollThumbPosition, scrollBoxWidth, scrollBoxLeft],
	)

	useEffect(() => {
		document.addEventListener('mouseleave', handleMouseUp)
		document.addEventListener('mouseup', handleMouseUp)
		document.addEventListener('mousemove', handleDocumentMouseMove)

		return () => {
			document.removeEventListener('mouseleave', handleMouseUp)
			document.removeEventListener('mouseup', handleMouseUp)
			document.removeEventListener('mousemove', handleDocumentMouseMove)
		}
	}, [handleDocumentMouseMove, handleMouseUp])

	const handleScrollThumbWidth = () => {
		const scrollHostElement = scrollHostRef.current
		const { clientWidth, scrollWidth } = scrollHostElement
		const scrollBoxPercentage = clientWidth / scrollWidth
		const scrollbarWidth = Math.max(scrollBoxPercentage * clientWidth, SCROLL_BOX_MIN_WIDTH)
		setScrollContainerWidth(scrollHostElement.offsetWidth)
		setScrollBoxWidth(scrollbarWidth)
		scrollHostElement.addEventListener('scroll', handleScroll, true)
		return function cleanup() {
			scrollHostElement.removeEventListener('scroll', handleScroll, true)
		}
	}

	useEffect(() => {
		handleScrollThumbWidth()
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleScrollThumbWidth, true)

		return () => {
			window.removeEventListener('resize', handleScrollThumbWidth, true)
		}
	}, [])

	return (
		<>
			{scrollBoxWidth < scrollContainerWidth && (
				<div className={'scroll-bar-horizontal'} style={{ opacity: hovering || isDragging ? 1 : 0 }}>
					<div
						className={'scroll-thumb'}
						onMouseDown={handleScrollThumbMouseDown}
						style={{ height: 10, width: scrollBoxWidth, left: scrollBoxLeft, top: 0 }}
					/>
				</div>
			)}
		</>
	)
}
