import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import styles from './styles/alertStyles'
const useStyles = makeStyles(styles)

const Alert = ({ text, confirmSuccess, props }) => {
  const classes = useStyles()

  return (
    <SweetAlert
      {...props}
      className={classes.root}
      title={props.title ? props.title : '¡Hecho!'}
      onConfirm={() => confirmSuccess()}
      confirmBtnCssClass={classes.confirmBtnCssClass}
    >
      {text}
    </SweetAlert>
  )
}

export default Alert
