import { useMemo, useEffect, useContext, useRef, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import classnames from 'classnames'
import { matchSorter } from 'match-sorter'
import { FormControl, Select, MenuItem, makeStyles, CircularProgress } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import { setTablePage } from 'local_redux/actions/tableActions'
import { setTablePageSize } from 'local_redux/actions/tableActions'
import { TABLE_UPDATE_FILTER } from 'local_redux/constants/tableConstants'
import { TABLE_REDUCER_RESET_FILTERS } from 'local_redux/constants/tableConstants'
import CustomScrollDiv from 'components/CustomScrollbar/CustomScrollbar'
import styles from './styles/reactTableStyles'
import { debounce } from 'shared/randomFunctions'
import { onChangeDelayTime } from 'shared/randomFunctions'

const useStyles = makeStyles(styles)

function DefaultColumnFilter({ column: { filterValue, setFilter, ...rest }, pageFetchingLogic, refetch }) {
	const dispatch = useDispatch()
	const filters = useSelector((state) => state.tableParams?.filters)
	const inputRef = useRef()

	const handleUpdateFilter = (e) => {
		if (!pageFetchingLogic) setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely\
		else {
			dispatch({ type: TABLE_UPDATE_FILTER, payload: { key: rest?.id, value: e.target.value } })
			onChangeDelayTime(inputRef, () => refetch(null, { ...(filters ?? {}), [rest?.id]: e.target.value }), 0.5)
		}
	}

	return (
		<CustomInput
			formControlProps={{
				fullWidth: true,
			}}
			ref={inputRef}
			inputProps={{
				value: pageFetchingLogic && filters?.[rest.id] ? filters?.[rest.id] ?? '' : filterValue || '',
				onChange: handleUpdateFilter,
				placeholder: `Buscar...`,
			}}
		/>
	)
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = (val) => !val

const ReactTable = ({ columns, data, displayButtons = false, tableStyles, refetch, pageFetchingLogic }) => {
	const classes = useStyles()
	const { setFilteredColumns, filteredRows, setfilteredRows } = useContext(FilterReactTableContext)
	const scrollHostRef = useRef()
	const [hovering, setHovering] = useState(false)

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			},
		}),
		[],
	)
	const defaultColumn = useMemo(
		() => ({ Filter: (props) => DefaultColumnFilter({ ...props, pageFetchingLogic, refetch }) }),
		[],
	)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		// state,
		// visibleColumns,
		nextPage,
		// pageCount,
		pageOptions,
		previousPage,
		canPreviousPage,
		canNextPage,
		setPageSize,
		gotoPage,
		rows,
		state: { pageIndex: pi, pageSize: ps },
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: { pageSize: !displayButtons ? 50 : 1000000, pageIndex: 0 },
			autoResetPage: false,
			autoResetFilters: false,
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination,
	)

	const tableParams = useSelector((state) => state.tableParams)

	const pageIndex = !pageFetchingLogic ? pi : tableParams?.page ?? 0
	const pageSize = !pageFetchingLogic ? ps : tableParams?.pageSize ?? 50
	const allPages = tableParams?.allPages ?? [0]

	const dispatch = useDispatch()

	useEffect(() => {
		return () => {
			dispatch({ type: TABLE_REDUCER_RESET_FILTERS })
		}
	}, [])

	useEffect(() => {
		// if (rows.length !== filteredRows.length) {
		const array = rows.map((row) => {
			return {
				...row.original,
			}
		})
		setfilteredRows(array)
		const data = columns?.filter((column) => column.Header !== '' && column.Header !== 'Acciones')
		setFilteredColumns(data)
		// }
	}, [data])

	const columnSize = []

	let numberOfRowsData = !displayButtons ? [5, 10, 20, 25, 50, 100, 500, 1000] : [Infinity]

	const valuesValidates = (pageOptions) => {
		let options = []
		for (let i = 0; i < pageOptions.length; i++) {
			options.push(pageOptions[i])
		}
		return options.length ? options : [0]
	}

	const onChangePage = (event) => {
		gotoPage(event.target.value)
		if (pageFetchingLogic) {
			dispatch(setTablePage(event.target.value))
			refetch({ page: event.target.value, pageSize })
		}
	}

	const onChangePageSize = (event) => {
		setPageSize(event.target.value)
		if (pageFetchingLogic) {
			dispatch(setTablePageSize(event.target.value))
			dispatch(setTablePage(0))
			refetch({ page: 0, pageSize: event.target.value })
		}
	}

	const handlePreviousPage = () => {
		if (pageFetchingLogic) {
			const newPageIndex = pageIndex === 0 ? 0 : pageIndex - 1

			dispatch(setTablePage(newPageIndex))
			refetch({ page: newPageIndex, pageSize })
		} else {
			previousPage()
		}
	}

	const handleNextPage = () => {
		if (pageFetchingLogic) {
			const newPageIndex = pageIndex === allPages.length - 1 ? allPages.length - 1 : pageIndex + 1

			dispatch(setTablePage(newPageIndex))
			refetch({ page: newPageIndex, pageSize })
		} else {
			nextPage()
		}
	}

	const handleMouseOver = useCallback(() => {
		setHovering(true)
	}, [])
	const handleMouseOut = useCallback(() => {
		setHovering(false)
	}, [])

	return (
		<>
			<div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className='custom-scrollbar'>
				<CustomScrollDiv scrollHostRef={scrollHostRef} hovering={hovering} />
			</div>
			<div
				ref={scrollHostRef}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				className='ReactTable -striped -highlight'
			>
				{!displayButtons && (
					<div className={'pagination-top' + ' ' + classes.pagPosition}>
						<div className='-pagination'>
							<div className='-previous'>
								<button
									type='button'
									onClick={handlePreviousPage}
									disabled={pageFetchingLogic ? pageIndex === 0 : !canPreviousPage}
									className='-btn'
								>
									Anterior
								</button>
							</div>
							<div className='-center'>
								<GridContainer className={classes.gridContainer}>
									<GridItem xs={12} sm={6} md={4}>
										{valuesValidates && (
											<FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
												<Select
													MenuProps={{
														className: classes.selectMenu,
													}}
													classes={{
														select: classes.select,
													}}
													value={pageIndex}
													onChange={onChangePage}
													inputProps={{
														name: 'pageSelect',
														id: 'page-select',
													}}
												>
													{(pageFetchingLogic ? allPages : valuesValidates(pageOptions)).map((prop, key) => {
														return (
															<MenuItem
																key={prop}
																classes={{
																	root: classes.selectMenuItem,
																	selected: classes.selectMenuItemSelected,
																}}
																value={prop}
															>
																Página {prop + 1}
															</MenuItem>
														)
													})}
												</Select>
											</FormControl>
										)}
									</GridItem>
									<GridItem xs={12} sm={6} md={4}>
										<FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
											<Select
												MenuProps={{
													className: classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												value={pageSize}
												onChange={onChangePageSize}
												inputProps={{
													name: 'numberOfRows',
													id: 'number-of-rows',
												}}
											>
												{numberOfRowsData.map((prop) => {
													return (
														<MenuItem
															key={prop}
															classes={{
																root: classes.selectMenuItem,
																selected: classes.selectMenuItemSelected,
															}}
															value={prop}
														>
															{prop} lineas
														</MenuItem>
													)
												})}
											</Select>
										</FormControl>
									</GridItem>
									{pageFetchingLogic && (
										<GridItem xs={2} className={classes.loader}>
											<CircularProgress
												style={{ color: '#333333', opacity: tableParams.loadingTableParams ? 1 : 0 }}
												size='20px'
											/>
										</GridItem>
									)}
								</GridContainer>
							</div>
							<div className='-next'>
								<button
									type='button'
									onClick={handleNextPage}
									disabled={pageFetchingLogic ? pageIndex === allPages.length - 1 : !canNextPage}
									className='-btn'
								>
									Siguiente
								</button>
							</div>
						</div>
					</div>
				)}
				<table {...getTableProps()} className={'rt-table ' + tableStyles} style={{ display: 'table', width: '100%' }}>
					<thead className='rt-thead -header'>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
								{headerGroup.headers.map((column, key) => {
									columnSize[key] = column.size
									if (column.hidden !== true) {
										return (
											<th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												className={classnames(
													'rt-th rt-resizable-header',
													{
														'-cursor-pointer': headerGroup.headers.length - 1 !== key,
														'-sort-asc': column.isSorted && !column.isSortedDesc,
														'-sort-desc': column.isSorted && column.isSortedDesc,
													},
													column.size !== undefined &&
														(column.size == 'small'
															? classes.widthSmall
															: column.size == 'big'
															? classes.widthBig
															: classes.widthMedium),
												)}
											>
												<div className='rt-resizable-header-content'>{column.render('Header')}</div>
												{/* Render the columns filter UI */}
												<div>
													{headerGroup.headers.length - 1 === key
														? null
														: column.canFilter
														? column.render('Filter')
														: null}
												</div>
											</th>
										)
									}
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className='rt-tbody'>
						{page.map((row, i) => {
							prepareRow(row)
							return (
								<tr
									{...row.getRowProps()}
									className={
										classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 }) + ` ${classes.hoverCell}`
									}
								>
									{row.cells.map((cell, i) => {
										if (cell.column.hidden !== true) {
											return (
												<td
													{...cell.getCellProps()}
													className={classnames(
														'rt-td',
														headerGroups[0]?.headers[i]?.size !== undefined &&
															(headerGroups[0]?.headers[i]?.size == 'small'
																? classes.widthSmall
																: headerGroups[0]?.headers[i]?.size == 'big'
																? classes.widthBig
																: headerGroups[0]?.headers[i]?.size == 'mini'
																? classes.widthMiniSmall
																: classes.widthMedium),
													)}
												>
													{cell.render('Cell')}
												</td>
											)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className='pagination-bottom'></div>
			</div>
		</>
	)
}
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id]
		return rowValue >= filterValue
	})
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

export default ReactTable
