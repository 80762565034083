import { createRef } from 'react'
import { useSelector } from 'react-redux'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import DocumentsContainer from 'components/DocumentsContainer/DocumentsContainer'
import InputFiles from './components/inputFiles'

const UploadDocumentsFiles = ({
	dniFront,
	setDniFront,
	dniBack,
	setDniBack,
	dniPDF,
	setDniPDF,
	fieldType,
	setFieldType,
	userInfo,
	onlyView,
}) => {
	const pdf = createRef()
	const front = createRef()
	const back = createRef()

	const pdfFile = fieldType === 'pdf'
	const jpgFile = fieldType === 'jpg'

	return (
		<DocumentsContainer title='Documentos'>
			<GridContainer>
				<GridItem xs={12}>
					{(pdfFile || userInfo.documentsFiles.some(({ name }) => name === 'documentPdfFile')) && (
						<InputFiles
							name='Archivo DNI'
							field='documentPdfFile'
							stateFile={dniPDF}
							setStateFile={setDniPDF}
							onlyView={onlyView}
							pdfFile
							reference={pdf}
						/>
					)}
					{(jpgFile ||
						userInfo.documentsFiles.some(({ name }) => name === 'profileDniFront' || name === 'profileDniBack')) && (
						<>
							<InputFiles
								name='Frente DNI'
								field='profileDniFront'
								stateFile={dniFront}
								setStateFile={setDniFront}
								jpgFile
								onlyView={onlyView}
								reference={front}
							/>
							<InputFiles
								name='Dorso DNI'
								field='profileDniBack'
								stateFile={dniBack}
								setStateFile={setDniBack}
								jpgFile
								onlyView={onlyView}
								reference={back}
							/>
						</>
					)}
				</GridItem>
				{userInfo.documentsFiles.length < 1 && onlyView && <>Este usuario no tiene DNI o documentos adjuntados</>}
				{userInfo.documentsFiles.length < 1 && !onlyView && (
					<>
						<GridItem xs={12} sm={6}>
							<Button
								color={pdfFile ? 'primary' : 'grey'}
								block
								onClick={() => {
									setDniFront('')
									setDniBack('')
									setFieldType('pdf')
								}}
							>
								Un único archivo
							</Button>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<Button
								color={jpgFile ? 'primary' : 'grey'}
								block
								onClick={() => {
									setDniPDF('')
									setFieldType('jpg')
								}}
							>
								Un archivo por cada cara
							</Button>
						</GridItem>
					</>
				)}
			</GridContainer>
		</DocumentsContainer>
	)
}

export default UploadDocumentsFiles
